/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { LEAD_CONSTANTS, CAN_USE_DOM } from 'common/constants';
import Icon from 'common/components/Icon';

import { COLLECTIONS, ERRORS_TEXTS } from 'client/constants';
import { Form, TextField, Checkbox } from 'client/components/common';
import { change, Field, initialize, reset } from 'redux-form';
import { createTeacherLead } from 'client/actions';
import { dataLayerEvents } from 'common/utils/gtag';
import arrowLeft from 'assets/client/images/arrow-left.svg';
import AlreadyInSystemPopup from '../Popups/AlreadyInSystemPopup';
import { validateEmail } from '../../../utils/vallidateEmail';
import NotStartedPopup from '../Popups/NotStartedPopup';
import SendedFormPopup from '../Popups/SendedFormPopup';
import { DatePicker } from '../Form';

import * as css from './TeachersForm.scss';

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;

type FormData = {
	fullName?: string,
	homePhone?: string,
	mobilePhone?: string,
	idNumber?: string,
	email?: string,
	birthDate?: string,
	study?: string,
	yearsOfTeaching?: string,
	address?: string,
	schoolName?: string,
	schoolPhone?: string,
	schoolCity?: string,
	principalName?: string,
	principalPhone?: string,
};

type Props = {
	values: FormData,
	errors: FormData,
	fields: { [key: string]: { touched: boolean } },
	createTeacherLead: typeof createTeacherLead,
	resetForm: Function,
};

type State = {
	isUserNotified: boolean,
	isShowSenededFormPopup: boolean,
	isShowAlreadyInSystemPopup: boolean,
	isLoading: boolean,
};

const placeholderTexts = {
	fullName: 'שם פרטי ושם משפחה:',
	idNumber: 'מספר תעודת זהות:',
	birthDate: 'תאריך לידה:',
	address: 'כתובת מגורים (ישוב, רחוב, מספר בית): ',
	mobilePhone: 'טלפון נייד:',
	homePhone: 'טלפון בבית:',
	email: 'כתובת דוא"ל:',
	study: 'מקצועות שמלמד/ת כיום:',
	yearsOfTeaching: 'מספר שנות ותק בהוראה: ',
	schoolName: 'שם בית-הספר שבו מלמד/ת:',
	schoolPhone: 'טלפון בית-הספר: ',
	schoolCity: 'יישוב בית-הספר:',
	principalName: 'שם מנהל/ת בית-הספר:',
	principalPhone: 'טלפון נייד של המנהל/ת:',
};

const FORM_CONFIG = {
	form: 'teacherInfo',
	validate: (values: FormData) => {
		const errorsObj = {};

		if (!values.fullName) {
			errorsObj.fullName = ERRORS_TEXTS.fullName;
		}

		// if (!values.homePhone) {
		// 	errorsObj.homePhone = ERRORS_TEXTS.phoneNumberNotValid;
		// }

		if (!values.mobilePhone) {
			errorsObj.mobilePhone = ERRORS_TEXTS.phoneNumberNotValid;
		} else if (values.mobilePhone) {
			const validPhone = phoneRegExp.test(values.mobilePhone.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.mobilePhone = ERRORS_TEXTS.wrongPhoneStructure;
			}
		}

		if (!values.email) {
			errorsObj.email = ERRORS_TEXTS.email;
		}

		if (values.email) {
			const validEmail = validateEmail(values.email.trim());
			if (!validEmail) {
				errorsObj.email = ERRORS_TEXTS.emailNotValid;
			}
		}

		if (!values.idNumber) {
			errorsObj.idNumber = ERRORS_TEXTS.idNumber;
		}

		if (!values.birthDate) {
			errorsObj.birthDate = ERRORS_TEXTS.birthDate;
		}

		if (!values.address) {
			errorsObj.address = ERRORS_TEXTS.address;
		}

		if (!values.study) {
			errorsObj.study = ERRORS_TEXTS.study;
		}

		if (!values.yearsOfTeaching) {
			errorsObj.yearsOfTeaching = ERRORS_TEXTS.yearsOfTeaching;
		}

		if (!values.schoolName) {
			errorsObj.schoolName = ERRORS_TEXTS.schoolName;
		}

		if (!values.schoolPhone) {
			errorsObj.schoolPhone = ERRORS_TEXTS.schoolPhone;
		}

		if (!values.schoolCity) {
			errorsObj.schoolCity = ERRORS_TEXTS.schoolCity;
		}

		if (!values.principalName) {
			errorsObj.principalName = ERRORS_TEXTS.principalName;
		}

		if (!values.principalPhone) {
			errorsObj.principalPhone = ERRORS_TEXTS.principalPhone;
		} else if (values.principalPhone) {
			const validPhone = phoneRegExp.test(values.principalPhone.toLocaleLowerCase());

			if (!validPhone) {
				errorsObj.principalPhone = ERRORS_TEXTS.wrongPhoneStructure;
			}
		}
		return errorsObj;
	},
};

class TeachersForm extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isShowSenededFormPopup: false,
			isShowAlreadyInSystemPopup: false,
			isLoading: false,
		};
	}

	componentDidMount() {
		const isCreatedLead = localStorage.getItem('teacherInfo');

		if (isCreatedLead) {
			this.setState({
				isShowAlreadyInSystemPopup: true,
			});
		}
	}
	initFormValues = async () => {};

	onFormSubmit = async (values: FormData) => {
		const { resetForm } = this.props;

		this.setState({
			isLoading: true,
		});

		const leadResult = await this.props.createTeacherLead<any>({
			collection: COLLECTIONS.TEACHER_LEADS,
			data: values,
		});

		if (leadResult.lead === LEAD_CONSTANTS.CREATED) {
			this.setState({
				isShowSenededFormPopup: true,
				isLoading: false,
			});

			localStorage.setItem('teacherInfo', 'true');

			resetForm('teacherInfo');

			dataLayerEvents.onFormSended(window.location.href);
		} else if (leadResult.lead === LEAD_CONSTANTS.FOUNDED) {
			this.setState({
				isShowAlreadyInSystemPopup: true,
				isLoading: false,
			});

			resetForm('teacherInfo');
		}
	};

	closeErrorPopup = () => {
		this.setState({
			isLoading: false,
		});
	};

	closeAlreadyInSystemPopup = () => {
		this.setState({
			isShowAlreadyInSystemPopup: false,
		});
	};

	closeSuccessPopup = () => {
		this.setState({
			isShowSenededFormPopup: false,
		});
	};

	render() {
		const { errors, fields } = this.props;
		const { isShowSenededFormPopup, isShowAlreadyInSystemPopup } = this.state;
		let macOs;

		if (CAN_USE_DOM) {
			macOs = /(Mac)/i.test(navigator.platform);
		}

		const isErrors = !_.isEmpty(errors);

		const isErrorFullName = isErrors && !!_.get(errors, 'fullName', '');
		const isErrorTeacherPhoneNumber =
			isErrors && (!!_.get(errors, 'mobilePhone', '') || !!_.get(errors, 'phoneNumberNotValid', ''));
		// const isErrorTeacherHomePhoneNumber =
		// 	isErrors && (!!_.get(errors, 'homePhone', '') || !!_.get(errors, 'phoneNumberNotValid', ''));
		const isErrorTeacherEmail = isErrors && (!!_.get(errors, 'email', '') || !!_.get(errors, 'emailNotValid', ''));
		const isErrorTeacherStudy = isErrors && !!_.get(errors, 'study', '');
		const isErrorTeacherAddress = isErrors && !!_.get(errors, 'address', '');
		const isErrorTeacherIdNumber = isErrors && !!_.get(errors, 'idNumber', '');
		const isErrorTeacherYearsOfTeaching = isErrors && !!_.get(errors, 'yearsOfTeaching', '');
		const isErrorTeacherBirthDate = isErrors && !!_.get(errors, 'birthDate', '');

		const isErrorSchoolName = isErrors && !!_.get(errors, 'schoolName', '');
		const isErrorSchoolPhone = isErrors && !!_.get(errors, 'schoolPhone', '');
		const isErrorSchoolCity = isErrors && !!_.get(errors, 'schoolCity', '');
		const isErrorPrincipalName = isErrors && !!_.get(errors, 'principalName', '');
		const isErrorPrincipalPhone =
			(isErrors && !!_.get(errors, 'principalPhone', '')) || !!_.get(errors, 'phoneNumberNotValid', '');

		return (
			<div className={css.formWrapper}>
				<Form className={css.form} config={FORM_CONFIG} onSubmit={this.onFormSubmit}>
					<div className={css.title}>
						<h3>פרטים אישיים של המורה המועמד/ת:</h3>
					</div>

					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorFullName && _.get(fields, 'fullName.touched', false) && css.error
								)}
							>
								<TextField
									name="fullName"
									placeholder={placeholderTexts.fullName}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorFullName && _.get(fields, 'fullName.touched', false) && (
									<p className={css.opinionLength}>{errors.fullName}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorTeacherPhoneNumber &&
										_.get(fields, 'mobilePhone.touched', false) &&
										css.error,
									css.withPhone
								)}
							>
								<TextField
									name="mobilePhone"
									placeholder={placeholderTexts.mobilePhone}
									pattern="^[0-9-+]+$"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
								{isErrorTeacherPhoneNumber && _.get(fields, 'mobilePhone.touched', false) && (
									<p className={css.opinionLength}>{errors.mobilePhone}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									// css.require,
									// isErrorTeacherHomePhoneNumber &&
									// 	_.get(fields, 'homePhone.touched', false) &&
									// 	css.error,
									css.withPhone
								)}
							>
								<TextField
									name="homePhone"
									placeholder={placeholderTexts.homePhone}
									pattern="^[0-9-+]+$"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
								{/* {isErrorTeacherHomePhoneNumber && _.get(fields, 'homePhone.touched', false) && (
									<p className={css.opinionLength}>{errors.homePhone}</p>
								)} */}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorTeacherEmail && _.get(fields, 'email.touched', false) && css.error
								)}
							>
								<TextField
									name="email"
									placeholder={placeholderTexts.email}
									isEmailField
									type="email"
								/>
								{isErrorTeacherEmail && _.get(fields, 'email.touched', false) && (
									<p className={css.opinionLength}>{errors.email}</p>
								)}
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorTeacherIdNumber && _.get(fields, 'idNumber.touched', false) && css.error
								)}
							>
								<TextField name="idNumber" placeholder={placeholderTexts.idNumber} type="number" />
								{isErrorTeacherIdNumber && _.get(fields, 'idNumber.touched', false) && (
									<p className={css.opinionLength}>{errors.idNumber}</p>
								)}
							</div>

							<div
								className={classNames(
									css.fieldWrapper,
									css.longField,
									css.require,
									isErrorTeacherAddress && _.get(fields, 'address.touched') && css.error
								)}
							>
								<TextField name="address" placeholder={placeholderTexts.address} />
								{isErrorTeacherAddress && _.get(fields, 'address.touched') && (
									<p className={css.opinionLength}>{errors.address}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									// css.longField,
									isErrorTeacherBirthDate && _.get(fields, 'birthDate.touched') && css.error
								)}
							>
								<DatePicker
									name="birthDate"
									defaultValue={null}
									placeholder={placeholderTexts.birthDate}
								/>

								{isErrorTeacherBirthDate && _.get(fields, 'birthDate.touched') && (
									<p className={css.opinionLength}>{errors.birthDate}</p>
								)}
							</div>
							<div className={css.flexRow}>
								{/* <div
									className={classNames(
										css.fieldWrapper,
										css.longField,
										css.require,
										isErrorTeacherAddress && _.get(fields, 'address.touched') && css.error
									)}
								>
									<TextField
										name="address"
										placeholder={placeholderTexts.address}
										pattern="^[\u0590-\u05FF /]+$"
									/>
									{isErrorTeacherAddress && _.get(fields, 'address.touched') && (
										<p className={css.opinionLength}>{errors.address}</p>
									)}
								</div>
								<div
									className={classNames(
										css.fieldWrapper,
										css.require,
										css.longField,
										isErrorTeacherBirthDate && _.get(fields, 'birthDate.touched') && css.error
									)}
								>
									<DatePicker
										name="birthDate"
										defaultValue={null}
										placeholder={placeholderTexts.birthDate}
									/>

									{isErrorTeacherBirthDate && _.get(fields, 'birthDate.touched') && (
										<p className={css.opinionLength}>{errors.birthDate}</p>
									)}
								</div> */}
								<div
									className={classNames(
										css.fieldWrapper,
										css.longField,
										css.require,
										isErrorTeacherYearsOfTeaching &&
											_.get(fields, 'yearsOfTeaching.touched') &&
											css.error
									)}
								>
									<TextField
										name="yearsOfTeaching"
										placeholder={placeholderTexts.yearsOfTeaching}
										type="number"
									/>
									{isErrorTeacherYearsOfTeaching && _.get(fields, 'yearsOfTeaching.touched') && (
										<p className={css.opinionLength}>{errors.yearsOfTeaching}</p>
									)}
								</div>
								<div
									className={classNames(
										css.fieldWrapper,
										css.longField,
										css.require,
										isErrorTeacherStudy && _.get(fields, 'study.touched') && css.error
									)}
								>
									<TextField
										name="study"
										placeholder={placeholderTexts.study}
										pattern="^[\u0590-\u05FF /]+$"
									/>
									{isErrorTeacherStudy && _.get(fields, 'study.touched') && (
										<p className={css.opinionLength}>{errors.study}</p>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorSchoolName && _.get(fields, 'schoolName.touched') && css.error
								)}
							>
								<TextField
									name="schoolName"
									placeholder={placeholderTexts.schoolName}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorSchoolName && _.get(fields, 'schoolName.touched') && (
									<p className={css.opinionLength}>{errors.schoolName}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorSchoolPhone && _.get(fields, 'schoolPhone.touched') && css.error,
									css.withPhone
								)}
							>
								<TextField
									name="schoolPhone"
									placeholder={placeholderTexts.schoolPhone}
									pattern="[0-9-+]"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
								{isErrorSchoolPhone && _.get(fields, 'schoolPhone.touched') && (
									<p className={css.opinionLength}>{errors.schoolPhone}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorSchoolCity && _.get(fields, 'schoolCity.touched') && css.error
								)}
							>
								<TextField
									name="schoolCity"
									placeholder={placeholderTexts.schoolCity}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorSchoolCity && _.get(fields, 'schoolCity.touched') && (
									<p className={css.opinionLength}>{errors.schoolCity}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorPrincipalName && _.get(fields, 'principalName.touched') && css.error
								)}
							>
								<TextField
									name="principalName"
									placeholder={placeholderTexts.principalName}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								{isErrorPrincipalName && _.get(fields, 'principalName.touched') && (
									<p className={css.opinionLength}>{errors.principalName}</p>
								)}
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.require,
									isErrorPrincipalPhone && _.get(fields, 'principalPhone.touched') && css.error,
									css.withPhone
								)}
							>
								<TextField
									name="principalPhone"
									placeholder={placeholderTexts.principalPhone}
									pattern="[0-9-+]"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
								{isErrorPrincipalPhone && _.get(fields, 'principalPhone.touched') && (
									<p className={css.opinionLength}>{errors.principalPhone}</p>
								)}
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.withButton)}>
						<button type="submit" className={classNames(css.submitBtn, !macOs && css.forWindows)}>
							<div className={css.submitButtonInner}>
								{this.state.isLoading ? (
									<Icon type="preloader" className={css.preloader} />
								) : (
									<>
										<span>שליחה</span>
										<img src={arrowLeft} alt="Submit" className={css.arrow} />
									</>
								)}
							</div>
						</button>
					</div>
				</Form>

				{/* <ErrorsPopup
					className={!isUserNotified && !_.isEmpty(errors) ? 'show' : ''}
					closePopup={this.closeErrorPopup}
					errors={errosWithoutRadioBtns}
				/> */}
				<AlreadyInSystemPopup
					className={isShowAlreadyInSystemPopup ? 'show' : ''}
					closePopup={this.closeAlreadyInSystemPopup}
				/>
				<NotStartedPopup className="" />
				<SendedFormPopup className={isShowSenededFormPopup ? 'show' : ''} closePopup={this.closeSuccessPopup} />
			</div>
		);
	}
}

const mapState = (state: ClientStore) => {
	const values = 'form.teacherInfo.values';
	const errors = 'form.teacherInfo.syncErrors';
	const fields = 'form.teacherInfo.fields';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
		fields: _.get(state, `${fields}`, {}),
	};
};

const mapDispatch = {
	initializeForm: initialize,
	createTeacherLead,
	resetForm: reset,
};

export default connect(mapState, mapDispatch)(TeachersForm);
