/* eslint-disable max-len */
// @flow

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	LEADS: 'leads',
	TEACHER_LEADS: 'teacher-leads',
};

export const PLACEHOLDERS_TEXTS = {
	fullName: 'שם מלא',
	kindergarten: 'שם הגן',
	locality: 'יישוב',
	phoneNumber: 'טלפון של הגננת',
	email: 'דוא”ל של הגננת',
	opinion: 'מדוע לדעתך הגננת ראויה לקבל את פרס "הגננת של המדינה”?',
	teacherInfluenceExample: 'כיצד הגננת משפיעה על חיי הילדים והמשפחה? (ספר/י על רגע משמעותי)',
	whatMakesTeacherSpecial: 'מה מייחד את הגננת בעיני הילדים? שתפי בתיאורים או ציטוטים מפיהם',
	valuesAndLessons: 'מהם הערכים והשיעורים לחיים שהגננת מקנה לילדים?',
	recommenderFullName: 'שם מלא',
	recommenderPhone: 'טלפון',
	recommenderEmail: 'כתובת הדוא"ל של הממליצ/ה',
};

export const ERRORS_TEXTS = {
	fullName: 'יש למלא שם מלא של הגננת',
	kindergarten: 'יש למלא את שם הגן',
	phoneNumber: 'יש למלא מספר טלפון של הגננת',
	phoneNumberNotValid: 'יש למלא מספר טלפון תקין של הממליצ/ה',
	email: 'יש למלא כתובת מייל',
	emailNotValid: 'יש למלא כתובת מייל תקינה',
	locality: 'יש למלא את שם היישוב',
	opinion: 'יש לכתוב מדוע המורה ראוי/ה לקבל את הפרס',
	opinionMaximum: 'יותר מ-1000 מילים',
	recommenderFullName: 'יש למלא שם מלא של הממליצ/ה',
	recommenderPhone: 'יש למלא מספר טלפון תתקין של הממליצ/ה',
	recommenderPhoneNotValid: 'יש למלא מספר טלפון תקין של הממליץ/ה',
	recommenderEmail: `יש למלא כתובת דוא''ל של הממליצ/ה`,
	recommenderEmailNotValid: 'יש למלא כתובת מייל תקינה',
	recommenderRelationship: 'יש לבחור את הקשר שלך לגננת',
	rateCriterias: 'יש לסמן ערך בין 1 ל 5',
	confirmCheckbox: 'יש לאשר קריאת התקנון',
	idNumber: 'יש למלא מספר תעודת זהות',
	teacherInfluenceExample: 'שדה חובה, יש להזין שדה זה',
	whatMakesTeacherSpecial: 'שדה חובה, יש להזין שדה זה',
	valuesAndLessons: 'שדה חובה, יש להזין שדה זה',
	radioError: 'req',
};

export const texts = {
	introTitle: 'אין על האוכל של סבתא שלי!',
};
